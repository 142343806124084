<template>
  <div>
    <!-- MODAL ADD\EDIT -->
    <b-modal
      id="price-project-modal"
      ref="price-project-modal"
      size="xl"
      :title="getTitle()"
      :ok-disabled="$v.$invalid"
      :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit"
      :busy="isBusy"
      @ok="checkIfValidThenEnter"
      @show="isModalLoading = true"
      @shown="loadData"
      @hidden="clearFields"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group v-slot="{ ariaDescribedbyPriceProject }" :disabled="modalType === 'edit'" class="mb-4 w-100">
          <b-form-radio v-model="selectedMode" inline :aria-describedby="ariaDescribedbyPriceProject" name="price-project-mode-radios" value="grp">
            {{ $t('table.GrpPrices') }}
          </b-form-radio>
          <b-form-radio v-model="selectedMode" inline :aria-describedby="ariaDescribedbyPriceProject" name="price-project-mode-radios" value="time">
            {{ $t('table.timeSlotPrices') }}
          </b-form-radio>
        </b-form-group>
        <div class="d-flex gap-3 w-80 mr-auto">
          <b-form-group style="flex-basis: 48%" :label="$t('table.name')" label-for="input-1-name">
            <b-form-input
              id="input-1-name"
              v-model.trim="name"
              type="text"
              required
              :placeholder="$t('table.name')"
              autofocus
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="!fromProject" style="flex-basis: 48%" :label="$t('broadcastSideBar.selectChannel')">
            <MultiSelect
              v-model="channel"
              class="mb-2"
              :options="channelsList"
              :placeholder="$t('broadcastSideBar.selectChannel')"
              label="name"
              track-by="id"
              :disabled="!canEdit"
              @input="getChannelPrimeInfo"
            ></MultiSelect>
          </b-form-group>
        </div>
        <div v-if="!fromProject" class="d-flex gap-3 w-80 mr-auto">
          <b-form-group style="flex-basis: 48%" :label="$t('channelModal.from')" label-for="day-from-datepicker">
            <datepicker-wrapper id="day-from-datepicker" v-model="date_from" required :disabled="!canEdit" @input="getChannelPrimeInfo" />
          </b-form-group>
          <b-form-group style="flex-basis: 48%" :label="$t('channelModal.to')" label-for="day-to-datepicker">
            <datepicker-wrapper id="day-to-datepicker" v-model="date_to" required :min="date_from" :max="yearLimit" :disabled="!canEdit" />
          </b-form-group>
        </div>
        <div v-if="selectedMode === 'grp'" class="d-flex flex-wrap gap-3 w-80 mr-auto">
          <b-form-group style="flex-basis: 48%" :label="$t('table.discountForLowPriority') + ' (' + $t('table.optional') + '), %'" label-for="input-1-l">
            <b-form-input
              id="input-1-l"
              v-model="low_priority_type_discount"
              type="number"
              placeholder="-100 — 100%"
              step="any"
              :disabled="!canEdit"
              max="100"
              min="-100"
            ></b-form-input>
          </b-form-group>
          <b-form-group style="flex-basis: 48%" :label="$t('table.discountForAutomatic') + ' (' + $t('table.optional') + '), %'" label-for="input-1-a">
            <b-form-input
              id="input-1-a"
              v-model="auto_placement_type_discount"
              type="number"
              placeholder="-100 — 100%"
              step="any"
              :disabled="!canEdit"
              max="100"
              min="-100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="d-flex flex-wrap gap-3 w-80 mr-auto">
          <b-form-group style="flex-basis: 48%" :label="$t('table.surchargeForCoBrand') + ' (' + $t('table.optional') + '), %'" label-for="input-1-cobrand">
            <b-form-input
              id="input-1-cobrand"
              v-model="co_branding_discount"
              type="number"
              placeholder="0 — 100%"
              step="any"
              :disabled="!canEdit"
              max="100"
              min="0"
            ></b-form-input>
          </b-form-group>
        </div>

        <b-form-checkbox v-model="is_proportional" class="mb-2" :disabled="!canEdit">
          {{ $t('table.proportional') }}
        </b-form-checkbox>

        <table v-if="!is_proportional" class="table my-4">
          <thead>
            <tr>
              <th v-for="(el, index) in s" :key="index">{{ el.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(el, index) in s" :key="index">
                <b-form-input v-model.number="el.value" size="sm" type="number" step="any" :disabled="!canEdit || is_proportional" required></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <p v-show="channelYearPrimeTime">
          <!-- eslint-disable vue/no-v-html -->
          <b>{{ $t('channelModal.prime') }}:</b> <span v-html="channelYearPrimeTime"></span>
          <!--eslint-enable-->
        </p>

        <template v-if="selectedMode === 'grp'">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('channelModal.measurements') }}</th>
                <th>{{ $t('channelModal.target') }}</th>
                <th>{{ $t('channelModal.prime') }}</th>
                <th>{{ $t('channelModal.offPrime') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in grpDetails" :key="index">
                <td>
                  <MultiSelect
                    v-model="row.measurement_company"
                    style="width: 250px"
                    class="multiselect-sm"
                    :options="measurementsList"
                    :placeholder="$t('channelModal.measurements')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit"
                    :allow-empty="false"
                    @input="loadTargetOptions(row.measurement_company, index)"
                  ></MultiSelect>
                </td>
                <td>
                  <MultiSelect
                    v-model="row.target_audience"
                    style="width: 250px"
                    class="multiselect-sm"
                    :options="row.target_options"
                    :placeholder="$t('channelModal.target')"
                    label="name"
                    track-by="id"
                    :disabled="!canEdit"
                    :allow-empty="false"
                  ></MultiSelect>
                </td>
                <td>
                  <currency-input v-model="row.prime_cost" :disabled="!canEdit" class="form-control-sm" required style="width: 150px" />
                </td>
                <td>
                  <currency-input v-model="row.off_prime_cost" :disabled="!canEdit" class="form-control-sm" required style="width: 150px" />
                </td>
                <td>
                  <button v-if="canEdit" class="btn-transparent trash-hover" :title="$t('table.delete')" type="button" @click="removeRowInGRP(index)">
                    <b-icon icon="trash"></b-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <b-button v-if="canEdit" type="button" class="ml-auto" size="sm" @click="addRowInGRP">
            {{ $t('table.addRow') }}
          </b-button>
        </template>

        <!-- FOR TIME SLOTS -->
        <template v-else>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('channelModal.from') }}</th>
                <th>{{ $t('channelModal.to') }}</th>
                <th>{{ $t('table.price') }}</th>
                <th class="week-day">{{ $t('table.mon') }}</th>
                <th class="week-day">{{ $t('table.tue') }}</th>
                <th class="week-day">{{ $t('table.wed') }}</th>
                <th class="week-day">{{ $t('table.thu') }}</th>
                <th class="week-day">{{ $t('table.fri') }}</th>
                <th class="week-day">{{ $t('table.sat') }}</th>
                <th class="week-day">{{ $t('table.sun') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in timeDetails" :key="index">
                <td>
                  <BroadcastTimePicker
                    :id="`timepicker-table-from-${index}`"
                    v-model="row.start_interval"
                    size="sm"
                    :interval-mode="true"
                    :disabled="!canEdit"
                    :min-time="getStartInterval(modalEditChannels, getYear(date_from ?? ''))"
                    :max-time="getStartInterval(modalEditChannels, getYear(date_from ?? '')) + 24 * 3600 - 1"
                    required
                  />
                </td>
                <td>
                  <BroadcastTimePicker
                    :id="`timepicker-table-to-${index}`"
                    v-model="row.end_interval"
                    size="sm"
                    :interval-mode="true"
                    :disabled="!canEdit"
                    :min-time="getStartInterval(modalEditChannels, getYear(date_from ?? ''))"
                    :max-time="getStartInterval(modalEditChannels, getYear(date_from ?? '')) + 24 * 3600 - 1"
                    required
                  />
                </td>
                <td>
                  <currency-input v-model="row.price" :disabled="!canEdit" class="form-control-sm" required style="width: 150px" />
                </td>
                <td><b-form-checkbox v-model="row.days" value="1" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="2" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="3" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="4" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="5" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="6" :disabled="!canEdit"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="row.days" value="7" :disabled="!canEdit"></b-form-checkbox></td>
                <td>
                  <b-icon v-if="canEdit" class="trash-hover cursor-pointer" icon="trash" :title="$t('table.delete')" @click="removeRowInTime(index)"></b-icon>
                </td>
              </tr>
            </tbody>
          </table>
          <b-button v-if="canEdit" class="ml-auto" size="sm" type="button" @click="addRowInTime">
            {{ $t('table.addRow') }}
          </b-button>
        </template>
      </form>
    </b-modal>

    <!-- CONFIRM ADD\EDIT -->
    <b-modal
      id="confirm-price-projects-confirm"
      ref="confirm-price-projects-confirm"
      size="sm"
      :title="modalType === 'add' ? $t('table.addNewPriceProjects') : $t('table.editPriceProjects')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isBusy"
      @ok.prevent="priceProject"
    >
      <p class="my-2">
        {{ modalType === 'add' ? $t('table.confirmAddPriceProjects') : $t('table.confirmEditPriceProjects') }}
        <span class="text-danger">{{ name }}</span>
        ?
      </p>
    </b-modal>

    <!-- MODAL DELETE -->
    <b-modal
      id="price-project-delete-modal"
      ref="price-project-delete-modal"
      :title="$t('table.deletePriceProjects')"
      :ok-title="$t('table.delete')"
      :cancel-title="$t('table.cancel')"
      auto-focus-button="ok"
      :busy="isBusy"
      @ok.prevent="deletePriceProjects"
    >
      <p class="my-2">
        {{ $t('table.confirmDeletePriceProjects') }}
        <span class="text-danger">{{ currentPriceProject ? currentPriceProject.name : '' }}</span>
        ?
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '@/components/SpinnerLoader';
import CurrencyInput from '@/components/CurrencyInput';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import broadcastTime from '@/mixins/broadcastTime';

export default {
  name: 'ModalPriceProjects',
  components: { MultiSelect, SpinnerLoader, CurrencyInput, DatepickerWrapper, BroadcastTimePicker },
  mixins: [timeConvertHelpers, broadcastTime],
  props: {
    currentPriceProject: { type: Object, default: undefined },
    modalType: { type: String, default: undefined },
    canEdit: { type: Boolean },
    fromProject: { type: Boolean },
    projectName: { type: String, default: undefined },
    projectRowIndex: { type: Number, default: undefined },
  },
  data() {
    return {
      selectedMode: 'grp',
      name: '',
      channel: '',
      channelYearPrimeTime: '',
      date_from: '',
      date_to: '',
      low_priority_type_discount: null,
      is_proportional: true,
      auto_placement_type_discount: null,
      co_branding_discount: null,
      s: [
        { name: '5"', value: '' },
        { name: '10"', value: '' },
        { name: '15"', value: '' },
        { name: '20"', value: '' },
        { name: '25"', value: '' },
        { name: '30"', value: '' },
        { name: '35"', value: '' },
        { name: '40"', value: '' },
        { name: '45"', value: '' },
        { name: '50"', value: '' },
        { name: '55"', value: '' },
        { name: '60"', value: '' },
      ],
      grpDetails: [
        {
          measurement_company: '',
          target_audience: '',
          prime_cost: '',
          off_prime_cost: '',
          target_options: [],
        },
      ],
      timeDetails: [
        {
          days: [],
          start_interval: '',
          end_interval: '',
          price: '',
        },
      ],
      isModalLoading: false,
      isBusy: false,
    };
  },
  validations() {
    const header = this.fromProject
      ? { name: { required } }
      : {
          name: { required },
          channel: { required },
          date_from: { required },
          date_to: { required },
        };
    const validIfProportional = this.is_proportional ? undefined : { s: { $each: { value: { required } } } }; // if proportional, don't valid
    if (this.selectedMode === 'grp') {
      return {
        ...header,
        ...validIfProportional,
        low_priority_type_discount: { minValue: minValue(-100), maxValue: maxValue(100) },
        auto_placement_type_discount: { minValue: minValue(-100), maxValue: maxValue(100) },
        co_branding_discount: { minValue: minValue(0), maxValue: maxValue(100) },
        grpDetails: {
          $each: {
            measurement_company: { required },
            target_audience: { required },
            prime_cost: { required },
            off_prime_cost: { required },
          },
        },
      };
    } else {
      return {
        ...header,
        ...validIfProportional,
        co_branding_discount: { minValue: minValue(0), maxValue: maxValue(100) },
        timeDetails: {
          $each: {
            days: { required },
            start_interval: { required },
            end_interval: { required },
            price: { required },
          },
        },
      };
    }
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      channelsList: 'getChannelsList',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      modalEditChannels: 'getModalEditChannels',
      modalEditPriceProjects: 'getModalEditPriceProjects',
    }),
    yearLimit() {
      return this.date_from ? this.date_from.slice(0, 4) + '-12-31' : undefined;
    },
  },
  watch: {
    date_from() {
      if (this.date_to) {
        const from = new Date(this.date_from);
        const to = new Date(this.date_to);
        // Reset date TO if it is lower than FROM
        if (from > to) this.date_to = '';
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getTitle() {
      if (this.modalType === 'add') return this.$i18n.t('table.addNewPriceProjects');
      else if (this.modalType === 'edit' && this.fromProject)
        // if modal was opened from Project modal
        return this.$i18n.t('table.editPriceProjects') + ' (' + this.$i18n.t('table.forProject') + ' ' + this.projectName + ')';
      else if (this.modalType === 'edit' && !this.canEdit) return this.name;
      else if (this.modalType === 'edit') return this.$i18n.t('table.editPriceProjects');
    },

    async loadData() {
      this.isModalLoading = true;
      if (!this.measurementsList || this.measurementsList.length < 1) await this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 });
      this.clearFields();
      if (this.modalType === 'edit' && this.currentPriceProject) {
        if (!this.fromProject) await this.$store.dispatch('GET_PRICE_PROJECTS_ID', { id: this.currentPriceProject.id }); // load edit data from Admin panel.
        const data = this.fromProject ? this.currentPriceProject : this.modalEditPriceProjects; // check if modal was opened from Project modal
        this.name = data.name;
        this.channel = this.channelsList.find((el) => el.id === data.channel_id);
        this.date_from = data.date_from;
        this.date_to = data.date_to;
        await this.getChannelPrimeInfo();
        this.s[0].value = data.s5;
        this.s[1].value = data.s10;
        this.s[2].value = data.s15;
        this.s[3].value = data.s20;
        this.s[4].value = data.s25;
        this.s[5].value = data.s30;
        this.s[6].value = data.s35;
        this.s[7].value = data.s40;
        this.s[8].value = data.s45;
        this.s[9].value = data.s50;
        this.s[10].value = data.s55;
        this.s[11].value = data.s60;
        this.is_proportional = data.is_proportional;
        this.removeRowInTime(0);
        this.removeRowInGRP(0);
        const type = data.type || data.price_project_type;
        this.co_branding_discount = data.co_branding_discount;
        if (type === 'fix_grp') {
          this.selectedMode = 'grp';
          this.auto_placement_type_discount = data.auto_placement_type_discount;
          this.low_priority_type_discount = data.low_priority_type_discount;
          const details = data.channel_project_price_project_by_grp_details || data.price_project_by_grp_details;
          if (details) {
            let index = 0;
            for (const detail of details) {
              const measurement_company = { id: detail.measurement_company_id };
              this.grpDetails.push({
                measurement_company: this.measurementsList.find((el) => el.id === detail.measurement_company_id),
                target_audience: '',
                prime_cost: detail.prime_cost,
                off_prime_cost: detail.off_prime_cost,
                target_options: [],
                header_id: detail.header_id,
              });
              // Load target list first
              await this.loadTargetOptions(measurement_company, index);
              this.grpDetails[index].target_audience = this.grpDetails[index].target_options.find((el) => el.id === detail.target_audience_id);
              index += 1;
            }
          }
        } else if (type === 'time_slots') {
          this.selectedMode = 'time';
          const details = data.price_project_by_time_details || data.channel_project_price_project_by_time_details;
          details?.forEach((detail) => {
            this.timeDetails.push({
              days: detail.days_of_week_iso || [],
              start_interval: detail.start_interval,
              end_interval: detail.end_interval,
              price: detail.price,
            });
          });
        }
      }
      this.isModalLoading = false;
    },

    clearFields() {
      this.name = '';
      this.selectedMode = 'grp';
      this.channel = '';
      this.channelYearPrimeTime = '';
      this.date_from = '';
      this.date_to = '';
      this.low_priority_type_discount = null;
      this.is_proportional = true;
      this.auto_placement_type_discount = null;
      this.co_branding_discount = null;
      this.s = [
        { name: '5"', value: '' },
        { name: '10"', value: '' },
        { name: '15"', value: '' },
        { name: '20"', value: '' },
        { name: '25"', value: '' },
        { name: '30"', value: '' },
        { name: '35"', value: '' },
        { name: '40"', value: '' },
        { name: '45"', value: '' },
        { name: '50"', value: '' },
        { name: '55"', value: '' },
        { name: '60"', value: '' },
      ];
      this.grpDetails = [
        {
          measurement_company: '',
          target_audience: '',
          prime_cost: '',
          off_prime_cost: '',
          target_options: [],
        },
      ];
      this.timeDetails = [
        {
          days: [],
          start_interval: '',
          end_interval: '',
          price: '',
        },
      ];
    },

    hideModalPriceProjects() {
      this.$bvModal.hide('price-project-modal');
    },

    async priceProject() {
      this.isBusy = true;

      const formData = {
        channel_id: this.channel.id,
        name: this.name,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.selectedMode === 'grp' ? 'fix_grp' : 'time_slots',
        s5: this.is_proportional ? undefined : this.s[0].value,
        s10: this.is_proportional ? undefined : this.s[1].value,
        s15: this.is_proportional ? undefined : this.s[2].value,
        s20: this.is_proportional ? undefined : this.s[3].value,
        s25: this.is_proportional ? undefined : this.s[4].value,
        s30: this.is_proportional ? 1 : this.s[5].value,
        s35: this.is_proportional ? undefined : this.s[6].value,
        s40: this.is_proportional ? undefined : this.s[7].value,
        s45: this.is_proportional ? undefined : this.s[8].value,
        s50: this.is_proportional ? undefined : this.s[9].value,
        s55: this.is_proportional ? undefined : this.s[10].value,
        s60: this.is_proportional ? undefined : this.s[11].value,
        is_proportional: this.is_proportional,
        auto_placement_type_discount: this.auto_placement_type_discount != null ? +this.auto_placement_type_discount : undefined,
        low_priority_type_discount: this.low_priority_type_discount != null ? +this.low_priority_type_discount : undefined,
        co_branding_discount: this.co_branding_discount != null ? +this.co_branding_discount : undefined,
      };
      if (this.selectedMode === 'grp') {
        let formGrpDetails = [];
        this.grpDetails.forEach((el) => {
          formGrpDetails.push({
            measurement_company_id: +el.measurement_company?.id,
            target_audience_id: +el.target_audience?.id,
            prime_cost: +el.prime_cost,
            off_prime_cost: +el.off_prime_cost,
            //header_id: el.header_id ? el.header_id : null,
          });
        });
        formData.price_project_by_grp_details = formGrpDetails;
      } else {
        // For time slots
        let formTimeDetails = [];
        this.timeDetails.forEach((el) => {
          formTimeDetails.push({
            days_of_week_iso: el.days,
            start_interval: +el.start_interval,
            duration: el.end_interval - el.start_interval,
            price: +el.price,
          });
        });
        formData.price_project_by_time_details = formTimeDetails;
      }
      if (this.modalType === 'add') {
        await this.$store.dispatch('POST_PRICE_PROJECTS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addPriceProjects'),
              text: this.name,
            });
            this.$emit('update');
            this.hideModalPriceProjects();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        // edit GRP
        const priceProjectId = this.modalEditPriceProjects.id;
        await this.$store.dispatch('PUT_PRICE_PROJECTS', {
          priceProjectId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editPriceProjects'),
              text: this.name,
            });
            this.$emit('update');
            this.hideModalPriceProjects();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.$bvModal.hide('confirm-price-projects-confirm');
      this.isBusy = false;
    },

    checkIfValidThenEnter(event) {
      if (!this.$v.$invalid && !this.isBusy) {
        if (this.fromProject) this.emitDataToProject();
        else {
          event.preventDefault();
          this.$bvModal.show('confirm-price-projects-confirm');
        }
      }
    },

    addRowInGRP() {
      this.grpDetails.push({
        measurement_company: '',
        target_audience: '',
        prime_cost: '',
        off_prime_cost: '',
        target_options: [],
        //header_id: this.modalEditPriceProjects ? this.modalEditPriceProjects.header.id : null,
      });
    },
    removeRowInGRP(data) {
      this.grpDetails.splice(data, 1);
    },

    addRowInTime() {
      this.timeDetails.push({
        days: [],
        start_interval: '',
        end_interval: '',
        price: '',
      });
    },
    removeRowInTime(data) {
      this.timeDetails.splice(data, 1);
    },

    async loadTargetOptions(measurement_company, index) {
      if (measurement_company) {
        await this.$store.dispatch('GET_TARGET', {
          'filter[measurement_company_id]': measurement_company.id,
        });
        this.grpDetails[index].target_options = this.targetList;
      } else {
        this.grpDetails[index].target_options = [];
      }
      this.grpDetails[index].target_audience = '';
    },

    async getChannelPrimeInfo() {
      if (this.channel && this.date_from) {
        const year = this.date_from.slice(0, 4);
        await this.$store.dispatch('GET_CHANNELS_ID', this.channel.id);
        let tempString = '';
        if (this.modalEditChannels.channel_settings[year]) {
          this.modalEditChannels.channel_settings[year].prime_time_datetimes.forEach((el) => {
            el.days.forEach((day, index) => {
              if (index !== 0) tempString += ', ';
              if (day == 1) {
                return (tempString += this.$i18n.t('table.mon'));
              } else if (day == 2) {
                return (tempString += this.$i18n.t('table.tue'));
              } else if (day == 3) {
                return (tempString += this.$i18n.t('table.wed'));
              } else if (day == 4) {
                return (tempString += this.$i18n.t('table.thu'));
              } else if (day == 5) {
                return (tempString += this.$i18n.t('table.fri'));
              } else if (day == 6) {
                return (tempString += this.$i18n.t('table.sat'));
              } else if (day == 7) {
                return (tempString += this.$i18n.t('table.sun'));
              }
            });
            tempString += ': ' + el.start_at + '-' + el.end_at + '; &nbsp;&nbsp;&nbsp;';
          });
          this.channelYearPrimeTime = tempString;
        }
      }
    },

    hideModalDeletePriceProjects() {
      this.$bvModal.hide('price-project-delete-modal');
    },

    async deletePriceProjects() {
      this.isBusy = true;
      const formData = this.currentPriceProject.id;
      const name = this.currentPriceProject.name;
      await this.$store.dispatch('DELETE_PRICE_PROJECTS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deletePriceProjects'),
            text: name,
          });
          this.hideModalDeletePriceProjects();
          this.$emit('update');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    emitDataToProject() {
      const formData = {
        id: +this.currentPriceProject?.id || undefined,
        price_project_id: this.currentPriceProject?.price_project_id,
        name: this.name,
        price_project_type: this.selectedMode === 'grp' ? 'fix_grp' : 'time_slots',
        auto_placement_type_discount: this.auto_placement_type_discount != null ? +this.auto_placement_type_discount : undefined,
        low_priority_type_discount: this.low_priority_type_discount != null ? +this.low_priority_type_discount : undefined,
        co_branding_discount: this.co_branding_discount != null ? +this.co_branding_discount : undefined,
        is_proportional: this.is_proportional,
        s5: this.is_proportional ? undefined : +this.s[0].value,
        s10: this.is_proportional ? undefined : +this.s[1].value,
        s15: this.is_proportional ? undefined : +this.s[2].value,
        s20: this.is_proportional ? undefined : +this.s[3].value,
        s25: this.is_proportional ? undefined : +this.s[4].value,
        s30: this.is_proportional ? 1 : +this.s[5].value,
        s35: this.is_proportional ? undefined : +this.s[6].value,
        s40: this.is_proportional ? undefined : +this.s[7].value,
        s45: this.is_proportional ? undefined : +this.s[8].value,
        s50: this.is_proportional ? undefined : +this.s[9].value,
        s55: this.is_proportional ? undefined : +this.s[10].value,
        s60: this.is_proportional ? undefined : +this.s[11].value,
      };
      if (this.selectedMode === 'grp') {
        let formGrpDetails = [];
        this.grpDetails.forEach((el) => {
          formGrpDetails.push({
            measurement_company_id: +el.measurement_company?.id,
            measurement_company_name: el.measurement_company?.name,
            target_audience_id: +el.target_audience?.id,
            target_audience_name: el.target_audience?.name,
            prime_cost: +el.prime_cost,
            off_prime_cost: +el.off_prime_cost,
            channel_project_price_project_id: +this.currentPriceProject?.id || undefined,
            //"id": el.id,
          });
        });
        formData.channel_project_price_project_by_grp_details = formGrpDetails;
      } else {
        // For time slots
        let formTimeDetails = [];
        this.timeDetails.forEach((el) => {
          formTimeDetails.push({
            days_of_week_iso: el.days,
            start_interval: el.start_interval,
            end_interval: el.end_interval,
            price: +el.price,
            channel_project_price_project_id: +this.currentPriceProject?.id || undefined,
            //"id": el.id,
          });
        });
        formData.channel_project_price_project_by_time_details = formTimeDetails;
      }
      this.$emit('updatePriceProjectInRow', formData, this.projectRowIndex);
      this.hideModalPriceProjects();
    },
  },
};
</script>

<style lang="sass">
.input-group__height-28px
  width: 150px
  button, input
    height: 28px

.week-day
  width: 70px
</style>
